import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import {
  IconFacebook,
  IconWhatsappDiana,
  IconWhatsappKarina,
} from "../atoms/Icons";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ContactPage = () => {
  return (
    <div>
      <Layout>
        <Seo title="Contacto" />
        <h1 className="title uppercase text-center pb-2">
          {" "}
          Donde nos Encontramos
        </h1>
        <div className="grid lg:grid-cols-2 place-items-center mx-auto pt-8 lg:pb-8 px-2 lg:px-0">
          <div className="max-w-2xl paragraph space-y-2">
            <p className="font-bold uppercase">SI VIENES DESDE ZIHUATANEJO </p>
            <p>
              Llegas al Aeropuerto internacional de Zihuatanejo, y desde allí
              puedes venir en taxi o en autobús (calcula aproximadamente 2
              horas), o también coordinar con nosotros el servicio de pick-up.
            </p>
            <p className="font-bold uppercase pt-6">
              SI VIENES DESDE LÁZARO CÁRDENAS
            </p>
            <p>
              Estamos a 72 km de Lázaro Cárdenas. Desde allí puedes venir en
              autobús o taxi hasta Nexpa. Estamos sobre el km 55 de la Carretera
              México 200.
            </p>
            <p className="font-bold uppercase pt-8">Contactanos </p>
            <div className="flex items-start gap-4 pb-4">
              <a
                aria-label="facebook link"
                href="https://m.facebook.com/Caba%C3%B1as-Vero-291936048071563/"
                className="hover:scale-105"
              >
                <IconFacebook />
              </a>
              <a
                href="https://wa.me/5214432653723"
                className="text-sm text-center hover:scale-105"
              >
                <IconWhatsappDiana />
              </a>
              <a
                href="https://wa.me/5214433060940"
                className="text-sm text-center hover:scale-105"
              >
                <IconWhatsappKarina />
              </a>
            </div>
          </div>
          <a
            href="https://www.google.com/maps/dir//caba%C3%B1as+vero+google+maps/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x84311ffd16a72765:0x4f50d41aeab0e06d?sa=X&ved=2ahUKEwiOmrn40Lf2AhUFlGoFHXK0AFcQ9Rd6BAg1EAM"
            className="lg:ml-auto pb-8"
          >
            <StaticImage
              width={560}
              height={506}
              src="../images/map.png"
              placeholder="tracedSVG"
              alt="map with location"
              className="mx-2 lg:px-0"
            />
          </a>
        </div>
      </Layout>
    </div>
  );
};

export default ContactPage;
